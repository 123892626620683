import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
  },
  plugins: [
    createPersistedState({
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
    }),
  ],
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    }
  },
  actions: {
    fetchUser({ commit }, data) {
      commit("SET_LOGGED_IN", data !== null);
      if (data!==null) {
        commit("SET_USER", {
          uid: data.usuario.id,
          nombre: data.usuario.nombre,
          paterno: data.usuario.paterno,
          materno: data.usuario.materno,
          correo: data.usuario.correo,
          token: data.token
        });
      } else {
        commit("SET_USER", null);
      }
    },
  },
  modules: {
  }
})
