import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import NProgress from "nprogress"
import "../../node_modules/nprogress/nprogress.css"
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import WelcomeView from '../views/WelcomeView.vue'
import AddView from '../views/AddView.vue'
import ListView from '../views/ListView.vue'

Vue.use(VueRouter)

const routes = [  
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/welcome',
    children:[
      {
        path: 'welcome',
        name: 'welcome',
        components: {default: WelcomeView},
        meta: { requiresAuth: true }
      },
      {
        path: 'add',
        name: 'add',
        components: {default: AddView},
        meta: { requiresAuth: true }
      },
      {
        path: 'list',
        name: 'list',
        components: {default: ListView},
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '*',
    redirect: '/login',
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.user.loggedIn) {
      next({ path: '/login' })
  }else if(!to.meta.requiresAuth){
    if (store.state.user.loggedIn) {
      next({ path: '/' })
    }else{
      next();
    }
  }else{
    next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router
