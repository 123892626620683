<template>
      <v-main class="grey lighten-4">
        <v-app-bar
          app
        
          dark
          flat
          clipped-left
        >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-avatar class="ml-10" tile><img src="../assets/logo.png" alt=""></v-avatar>
          <v-toolbar-title class="ml-4 d-flex flex-column">
            <span>Denuncias</span>
            <span class="text-caption">Sistema Estatal de Información</span>
          </v-toolbar-title>          
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            app
            clipped
            dark
          >
              <v-list color="primary" dark>
                  <v-list-item two-line class="px-2">
                  <v-list-item-avatar>
                    <v-img src="../assets/img/user.png"></v-img>
                  </v-list-item-avatar>

                      <v-list-item-content>
                      <v-list-item-subtitle class="text-truncate white--text" >
                          <strong>{{$store.state.user.data.nombre}} {{$store.state.user.data.paterno }} {{$store.state.user.data.materno }}</strong>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-truncate white--text">
                      {{$store.state.user.data.correo}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-truncate white--text">
                      
                      </v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item> 
              </v-list>
              <v-list dense class="ml-n2" >
              <v-list-item-group
                active-class="primary">
              <v-list-item
                v-for="l in links"
                :key="l.route"
                :to="l.route"
                link
              >
                <v-list-item-avatar size="34" tile>
                    <img
                      :src="l.icon"
                      alt=""
                    >
                  </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ l.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-slot:append>
            <div class="mb-5">
              <v-btn  block color="primary" dark @click="logout">
                <v-icon left>
                  mdi-location-exit
                </v-icon>
                {{drawer? 'Salir' :''}}
              </v-btn>
            </div>
          </template>
        </v-navigation-drawer>
        <router-view/>
      </v-main>
</template>
<script>
export default {
  data: () => ({
      drawer:true,
      links:[
        {text:'Bienvenido', route:'welcome', icon: require('@/assets/img/welcome.png')},
        {text:'Agregar Denuncia', route:'add', icon: require('@/assets/img/add.png')},
        {text:'Mis Denuncias', route:'list', icon: require('@/assets/img/list.png')},
      ]
  }),
  methods:{
    logout(){
      const _this = this;
      this.$swal({
          title: '¿Estas seguro que deseas cerrar sesión?',
          showCancelButton: true,
          confirmButtonText: 'Si, salir',
          cancelButtonText:'Cancelar',
          icon:'question'
        }).then((result) => {
          if (result.isConfirmed) {
            _this.$store.dispatch("fetchUser", null);
            _this.$router.push('/login');
          }
        });
      
    }
  },
}
</script>
