<template>
      <v-main>
        <v-app-bar
            app
            dark
            height="100"
        >
        <v-avatar class="ml-5" size="80" tile><img src="../assets/logo.png" alt=""></v-avatar>
        <div class="d-flex align-center">
          <v-toolbar-title class="ml-5 shrink hidden-md-and-up" transition="scale-transition" ><span class="text-h4"><span style="color:#99cccc;">D</span>ENUNCIAS</span> </v-toolbar-title>
          <v-toolbar-title class="ml-5 shrink hidden-sm-and-down" ><span class="text-h4"><span style="color:#99cccc;">SISTEMA</span> ESTATAL DE DENUNCIAS</span> </v-toolbar-title>
        </div>
        </v-app-bar>
        <v-container fluid class="fill-height">
            <v-row justify="center">
                <v-col cols="12" md="4" sm="8">
                    <v-card elevation="8" shaped>
                        <v-card-title>Iniciar Sesión</v-card-title>
                        <v-card-subtitle>Bienvenido</v-card-subtitle>
                        <v-card-text>
                            <form>
                                <v-text-field
                                    label="Correo"
                                    v-model="usuario"
                                    autocomplete="email"
                                    prepend-inner-icon="mdi-email"
                                    filled
                                    outlined
                                    dense
                                    placeholder=" "
                                    persistent-placeholder
                                ></v-text-field>
                                <v-text-field
                                    label="Contraseña"
                                    v-model="pass"
                                    prepend-inner-icon="mdi-lock"
                                    filled
                                    :type="hidden ? 'password':'text'"
                                    outlined
                                    autocomplete="current-password"
                                    dense
                                    :append-icon="hidden ? 'mdi-eye-off':'mdi-eye'"
                                    @click:append="hidden=!hidden"
                                    placeholder=" "
                                    persistent-placeholder
                                ></v-text-field>
                            </form>
                        
                            ¿Aún no tienes una cuenta? <router-link to="/register">Registrate</router-link>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="logIn" :loading="loading" :disabled="loading">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
      </v-main>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    usuario:'',
    pass:'',
    hidden:true,
    loading : false,
  }),
  methods:{
      logIn(){
        const _this = this;
        _this.loading=true;
        axios.post("https://seicoahuila.org.mx/api/login", {
            correo: this.usuario,
            password: this.pass
        }).then(function (response) {
            if(response.data.login){
                _this.$store.dispatch("fetchUser", response.data);
                _this.$router.push('/');
            }else{
                _this.$swal({title:'Oops...!', text: response.data.error,icon: 'error'});
            }
        }).catch(error=>{
            _this.$swal({title:'Oops...!', text: error,icon:'error'});
        }).finally(()=>_this.loading=false);
    }
  }
}
</script>