<template>
  <v-main>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container fluid class="fill-height">
      <v-row justify="center">
        <v-col cols="12" md="6" sm="10">
          <v-card elevation="8" shaped>
            <v-card-title>Registro</v-card-title>
            <v-card-subtitle>Llena todo los campos para registrarte</v-card-subtitle>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Nombre"
                      v-model="nombre"
                      prepend-inner-icon="mdi-account-circle"
                      filled
                      outlined
                      dense
                      required
                      :counter="50"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Apellido Paterno"
                      v-model="paterno"
                      prepend-inner-icon="mdi-account-circle"
                      filled
                      outlined
                      dense
                      required
                      :counter="150"
                      :rules="paternoRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Apellido Materno"
                      v-model="materno"
                      prepend-inner-icon="mdi-account-circle"
                      filled
                      outlined
                      dense
                      :counter="150"
                      :rules="maternoRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Correo"
                      v-model="usuario"
                      autocomplete="email"
                      prepend-inner-icon="mdi-email"
                      filled
                      outlined
                      dense
                      placeholder=" "
                      persistent-placeholder
                      required
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Confirma correo"
                      v-model="usuario2"
                      autocomplete="email"
                      prepend-inner-icon="mdi-email-check"
                      filled
                      outlined
                      dense
                      placeholder=" "
                      persistent-placeholder
                      required
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Contraseña"
                      v-model="pass"
                      prepend-inner-icon="mdi-lock"
                      filled
                      :type="hidden ? 'password' : 'text'"
                      outlined
                      dense
                      autocomplete="current-password"
                      :append-icon="hidden ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="hidden = !hidden"
                      placeholder=" "
                      persistent-placeholder
                      required
                      :rules="passRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Confirma contraseña"
                      v-model="pass2"
                      prepend-inner-icon="mdi-lock-check"
                      filled
                      :type="hidden ? 'password' : 'text'"
                      outlined
                      dense
                      autocomplete="current-password"
                      :append-icon="hidden ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="hidden = !hidden"
                      placeholder=" "
                      persistent-placeholder
                      required
                      :rules="passRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              ¿Ya tienes una cuenta?
              <router-link to="/login">Inicia sesi&oacute;n</router-link>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="register" :loading="loading" :disabled="loading"
                >Registrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    nombre: "",
    paterno: "",
    materno: "",
    usuario: "",
    usuario2: "",
    pass: "",
    pass2: "",
    valid: false,
    loading: false,
    hidden: true,
    nameRules: [
      (v) => !!v || "Nombre es requerido",
      (v) => (v && v.length <= 50) || "Nombre no debe ser mayor a 50 caracteres",
    ],
    paternoRules: [
      (v) => !!v || "Apellido paterno es requerido",
      (v) =>
        (v && v.length <= 150) || "Apellido paterno no debe ser mayor a 150 caracteres",
    ],
    maternoRules: [
      (v) => v.length <= 150 || "Apellido materno no debe ser mayor a 150 caracteres",
    ],
    emailRules: [
      (v) => !!v || "Correo es requerido",
      (v) => /.+@.+\..+/.test(v) || "Correo invalido",
    ],
    passRules: [
      (v) => !!v || "Contraseña es requerida",
      (v) => (v && v.length >= 5) || "Contraseña debe ser mayor a 5 caracteres",
      //v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
      //v => /(?=.*\d)/.test(v) || 'Must have one number',
      //v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
    ],
  }),
  methods: {
    register() {
      const _this = this;
      if (_this.$refs.form.validate()) {
        if (_this.usuario == _this.usuario2) {
          if (_this.pass == _this.pass2) {
            axios
              .post("https://seicoahuila.org.mx/api/registro", {
                correo: _this.usuario,
                password: _this.pass,
                nombre: _this.nombre,
                paterno: _this.paterno,
                materno: _this.materno ?? "",
              })
              .then(function (response) {
                if (response.data.code == 0) {
                  _this
                    .$swal("Registro!", "Registro completado correctamente!", "success")
                    .then(() => _this.$router.push("/login"));
                } else {
                  _this.$swal("Oops...!", response.data.message, "error");
                }
              })
              .catch((error) => {
                _this.$swal("Oops...!", error.message, "error");
              })
              .finally(() => (_this.loading = false));
          } else {
            _this.$swal("Oops...!", "Las contraseñas no coinciden!", "error");
          }
        } else {
          _this.$swal("Oops...!", "Los correos no coinciden!", "error");
        }
      } else {
        _this.$swal("Oops...!", "Completa correctamente los campos!", "error");
      }
    },
  },
};
</script>
