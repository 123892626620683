<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-card class="mx-auto">
          <v-toolbar color="secondary" dark>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Mis Denuncias</v-toolbar-title>
          </v-toolbar>
          <v-expansion-panels>
            <v-expansion-panel v-for="(d, index) in denuncias" :key="d.id">
              <v-expansion-panel-header ripple>
                <v-list-item two-line dense class="ma-n5">
                  <v-list-item-avatar size="64">
                    <v-img src="../assets/img/denuncia.png" contain></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="d.dependencium.nombre"></v-list-item-title>

                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="d.area_dependencia"
                    ></v-list-item-subtitle>

                    <v-list-item-subtitle>{{
                      d.descripcion | truncate
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>{{
                      d.createdAt | ago
                    }}</v-list-item-action-text>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="black lighten-1" v-bind="attrs" v-on="on">
                          {{
                            d.status == 1
                              ? "mdi-email-send"
                              : d.status == 2
                              ? "mdi-eye-refresh"
                              : "mdi-gavel"
                          }}
                        </v-icon>
                      </template>
                      <span>{{
                        d.status == 1
                          ? "Enviada"
                          : d.status == 2
                          ? "Vista | En proceso"
                          : "Resuelta"
                      }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card outlined min-height="220">
                      <v-card-text>
                        <div class="text-overline">Denunciado:</div>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-row>
                              <v-col cols="12">
                                <p class="text--primary">
                                  <span class="text-overline">Descripci&oacute;n:</span>
                                  <span class="font-italic">{{
                                    d.denunciado.descripcion
                                  }}</span>
                                </p>
                              </v-col>
                              <v-col cols="12" class="mt-n12">
                                <p class="text--primary">
                                  <span class="text-overline">Alias:</span>
                                  <span class="font-italic">{{
                                    d.denunciado.alias
                                  }}</span>
                                </p>
                              </v-col>
                              <v-col cols="12" class="mt-n12">
                                <p class="text--primary">
                                  <span class="text-overline">G&eacute;nero:</span>
                                  <span class="font-italic">{{
                                    d.denunciado.genero.descripcion
                                  }}</span>
                                </p>
                              </v-col>
                              <v-col cols="12" class="mt-n12">
                                <p class="text--primary">
                                  <span class="text-overline">Cargo:</span>
                                  <span class="font-italic">{{
                                    d.denunciado.cargo == null ? "" : d.denunciado.cargo
                                  }}</span>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="4" class="mt-n10">
                            <v-card outlined shaped>
                              <v-img
                                height="100"
                                contain
                                :src="
                                  d.denunciado.fotografia
                                    ? 'https://seicoahuila.org.mx/api' +
                                      d.denunciado.fotografia
                                    : require('../assets/img/desconocido.webp')
                                "
                              ></v-img>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="mt-n10">
                            <p class="text--primary">
                              <span class="text-overline">Nombre:</span>
                              <span class="font-italic"
                                ><strong
                                  >{{ d.denunciado.nombre }} {{ d.denunciado.paterno }}
                                  {{ d.denunciado.materno }}</strong
                                ></span
                              >
                            </p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card outlined min-height="220">
                      <v-card-text>
                        <div class="text-overline">Dependencia:</div>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-row>
                              <v-col cols="12">
                                <p class="text--primary">
                                  <span class="text-overline">&Aacute;rea:</span>
                                  <span class="font-italic">{{
                                    d.area_dependencia
                                  }}</span>
                                </p>
                              </v-col>
                              <v-col cols="12" class="mt-n12">
                                <p class="text--primary">
                                  <span class="text-overline">Lugar:</span>
                                  <span class="font-italic">{{ d.lugar }}</span>
                                </p>
                              </v-col>
                              <v-col cols="12" class="mt-n12">
                                <p class="text--primary">
                                  <span class="text-overline">Tr&aacute;mite:</span>
                                  <span class="font-italic">{{ d.tramite }}</span>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="4" class="mt-n10">
                            <v-card outlined shaped>
                              <v-img
                                height="100"
                                contain
                                :src="require('../assets/img/dependencia.png')"
                              ></v-img>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="mt-n10">
                            <p class="text--primary">
                              <span class="text-overline">Nombre:</span>
                              <span class="font-italic"
                                ><strong>{{ d.dependencium.nombre }}</strong></span
                              >
                            </p>
                          </v-col>
                          <v-col cols="12" class="mt-n12">
                            <p class="text--primary">
                              <span class="text-overline">Direcci&oacute;n:</span>
                              <span class="font-italic"
                                >{{ d.dependencium.calle }}, {{ d.dependencium.colonia }},
                                {{ d.dependencium.ciudad }}, CP
                                {{ d.dependencium.cp }}</span
                              >
                            </p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="12" class="mt-n5">
                    <v-card outlined>
                      <v-card-text>
                        <div class="text-overline">Denuncia</div>
                        <v-row>
                          <v-col cols="12">
                            <p class="text--primary">
                              <span class="text-overline">Descripci&oacute;n:</span>
                              <span class="font-italic">{{ d.descripcion }}</span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="mt-n10">
                          <v-col cols="12" md="6">
                            <p class="text--primary">
                              <span class="text-overline">Estatus:</span>
                              <v-icon color="black lighten-1" class="ml-2" left>
                                {{
                                  d.status == 1
                                    ? "mdi-email-send"
                                    : d.status == 2
                                    ? "mdi-eye"
                                    : "mdi-gavel"
                                }}
                              </v-icon>
                              <span class="font-italic">{{
                                d.status == 1
                                  ? "Enviada"
                                  : d.status == 2
                                  ? "En proceso"
                                  : "Resuelta"
                              }}</span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="mt-n10">
                          <v-col cols="12" md="6">
                            <p class="text--primary">
                              <span class="text-overline">Fecha de Hechos:</span>
                              <span class="font-italic"
                                ><strong>{{ d.fecha | time }}</strong></span
                              >
                            </p>
                          </v-col>
                          <v-col cols="12" md="6">
                            <p class="text--primary">
                              <span class="text-overline"
                                >&Uacute;ltima actualizaci&oacute;n:</span
                              >
                              <span class="font-italic"
                                ><strong>{{ d.updatedAt | time }}</strong></span
                              >
                            </p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="12" class="mt-n5" v-if="d.pruebas.length > 0">
                    <v-card outlined>
                      <v-card-text>
                        <div class="text-overline">Pruebas</div>
                        <v-row>
                          <v-col cols="12" md="3" v-for="p in d.pruebas" :key="p.id">
                            <v-card class="mx-auto my-12" max-width="250">
                              <v-img
                                height="150"
                                contain
                                :src="
                                  p.mime.includes('image')
                                    ? `https://seicoahuila.org.mx/api${p.url}`
                                    : require('../assets/img/youtube.png')
                                "
                              ></v-img>

                              <v-card-actions>
                                <v-btn
                                  color="primary"
                                  text
                                  target="_blank"
                                  :href="`https://seicoahuila.org.mx/api${p.url}`"
                                  download="proposed_file_name"
                                >
                                  Ver
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="12" class="mt-n5" v-if="d.status == 3">
                    <v-card outlined>
                      <v-card-text>
                        <div class="font-weight-black text-h6 black--text">
                          Resolución
                        </div>
                        <v-row>
                          <v-col cols="12">
                            <p class="text--primary">
                              <span class="text-overline">Fundamento:</span>
                              <span class="font-italic">{{
                                d.resolucion.fundamento
                              }}</span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                          <v-col cols="12">
                            <p class="text--primary">
                              <span class="text-overline red--text"
                                >Autoridad Sancionadora:</span
                              >
                              <span class="font-italic">
                                {{ d.resolucion.autoridad_sancionadora }}</span
                              >
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <p class="text--primary">
                              <span class="text-overline">Sanciones:</span>
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                          <v-col cols="12">
                            <v-simple-table dense dark>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">Falta</th>
                                    <th class="text-left">Sanci&oacute;n</th>
                                    <th class="text-left" colspan="3">
                                      Detalles de sanci&oacute;n
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="rs in d.resolucion.resolucion_sancions"
                                    :key="rs.id"
                                  >
                                    <td>{{ rs.tipo_faltum.descripcion }}</td>
                                    <td
                                      :colspan="
                                        rs.id_tipo_sancion == 'D'
                                          ? '1'
                                          : rs.id_tipo_sancion == 'I' ||
                                            rs.id_tipo_sancion == 'S'
                                          ? '3'
                                          : '2'
                                      "
                                    >
                                      {{ rs.tipo_sancion.descripcion }}
                                    </td>
                                    <td
                                      v-if="
                                        rs.id_tipo_sancion == 'I' ||
                                        rs.id_tipo_sancion == 'S'
                                      "
                                      class="pt-3"
                                    >
                                      <p>{{ rs.plazo_inhabilitacion }} d&iacute;as</p>
                                    </td>
                                    <td
                                      v-if="
                                        rs.id_tipo_sancion == 'I' ||
                                        rs.id_tipo_sancion == 'S'
                                      "
                                      class="pt-3"
                                    >
                                      <p>Desde: {{ rs.fecha_inicio | date }}</p>
                                    </td>
                                    <td
                                      v-if="
                                        rs.id_tipo_sancion == 'I' ||
                                        rs.id_tipo_sancion == 'S'
                                      "
                                      class="pt-3"
                                    >
                                      <p>Hasta: {{ rs.fecha_fin | date }}</p>
                                    </td>
                                    <td
                                      v-if="
                                        rs.id_tipo_sancion == 'SE' ||
                                        rs.id_tipo_sancion == 'IRSC' ||
                                        rs.id_tipo_sancion == 'M'
                                      "
                                      class="pt-3"
                                    >
                                      <p>$ {{ rs.monto }} MXN</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                            v-for="p in d.resolucion.documentos"
                            :key="p.id"
                          >
                            <v-card class="mx-auto my-12">
                              <v-img
                                height="80"
                                contain
                                :src="
                                  p.url.includes('.pdf') || p.url.includes('.PDF')
                                    ? require('../assets/img/pdf.png')
                                    : `https://seicoahuila.org.mx/api${p.url}`
                                "
                              ></v-img>
                              <v-card-text>
                                <p class="mt-n5">
                                  <span class="text-overline"
                                    >Descripci&oacute;n del Documento:<br /> </span
                                  ><span class="black--text">{{ p.descripcion }}</span>
                                </p>
                              </v-card-text>
                              <v-card-actions>
                                <v-btn
                                  color="primary"
                                  text
                                  target="_blank"
                                  :href="`https://seicoahuila.org.mx/api${p.url}`"
                                  download="proposed_file_name"
                                >
                                  Ver Documento
                                </v-btn>
                                <v-spacer></v-spacer>
                                <p class="font-italic mt-2">
                                  {{ p.fecha_expedicion | date }}
                                </p>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
              <v-divider v-if="index < denuncias.length - 1" :key="index"></v-divider>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    denuncias: [],
  }),
  mounted() {
    const _this = this;
    let config = { headers: { "access-token": _this.$store.state.user.data.token } };
    axios
      .get("https://seicoahuila.org.mx/api/denuncias/status", config)
      .then(function (response) {
        _this.denuncias = response.data;
      })
      .catch((error) => {
        _this.onError(error);
      });
  },
  filters: {
    ago: function (value) {
      if (!value) return "";
      value = moment(value).fromNow();
      return value;
    },
    date: function (value) {
      if (!value) return "";
      return moment(value).local().format("DD/MM/YYYY");
    },
    time: function (value) {
      if (!value) return "";
      return moment(value).local().format("DD/MM/YYYY hh:mm a");
    },
    truncate: function (text) {
      return text.length > 80 ? text.slice(0, 80) + "..." : text;
    },
  },
  methods: {
    onError(error) {
      const _this = this;
      console.log(error);
      switch (error.response.status) {
        case 401:
          _this
            .$swal({
              title: "Oops...!",
              text: "Sesión expirada, inicia sesion nuevamente!",
              icon: "error",
            })
            .then(() => {
              _this.$store.dispatch("fetchUser", null);
              _this.$router.push("/login");
            });
          break;
        default:
          _this.$swal({ title: "Oops...!", text: error.message, icon: "error" });
          break;
      }
    },
  },
};
</script>
