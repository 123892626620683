<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card elevation="8">
          <v-toolbar color="secondary" dark>
            <v-toolbar-title class="text-center text-h6"
              ><v-icon left large>mdi-account-details</v-icon>Capturar queja o denuncia
              {{
                dependencia != ""
                  ? `a ${dependencias.find((d) => d.id == dependencia).nombre}`
                  : ""
              }}</v-toolbar-title
            >
          </v-toolbar>
          <v-stepper v-model="paso">
            <v-stepper-header>
              <v-stepper-step :complete="paso > 1" step="1"> Dependencia </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="paso > 2" step="2">
                Datos de la denuncia
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step :complete="paso > 3" step="3"> Pruebas </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step :complete="paso > 4" step="4">
                Servidor P&uacute;blico Denunciado
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="5"> Denunciante </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form ref="form1" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="dependencia"
                          :items="dependencias"
                          dense
                          outlined
                          item-text="nombre"
                          item-value="id"
                          label="Nombre de la dependencia"
                          hint="Selecciona la dependencia del servidor público"
                          persistent-hint
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-actions>
                  <v-btn color="primary" @click="validarPaso1"> Siguiente </v-btn>
                </v-card-actions>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card-subtitle v-if="$vuetify.breakpoint.mobile" class="text-center"
                  ><v-icon left>mdi-archive-edit</v-icon
                  ><strong>Datos de la denuncia</strong></v-card-subtitle
                >
                <v-form ref="form2" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="descripcion"
                          outlined
                          label="Descripción de la queja / denuncia"
                          hint="Describa detalladamente los hechos ocurridos"
                          persistent-hint
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fechaEvento"
                              label="Fecha del hecho"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              dense
                              outlined
                              v-on="on"
                              required
                              :rules="requeridoRules"
                              @blur="date = parseDate(fechaEvento)"
                            >
                              <template v-slot:append>
                                <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="selectedDate"
                            :active-picker.sync="activePicker"
                            :max="
                              new Date(
                                Date.now() - new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                            min="1950-01-01"
                            @change="save"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Ubicación"
                          v-model="ubicacion"
                          prepend-inner-icon="mdi-map-marker-alert-outline"
                          outlined
                          dense
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Area de la dependencia"
                          v-model="area"
                          prepend-inner-icon="mdi-office-building-marker-outline"
                          outlined
                          dense
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Tramite o servicio"
                          v-model="tramite"
                          prepend-inner-icon="mdi-office-building-marker-outline"
                          outlined
                          hint="Tramite o servicio que se intento realizar o realizó"
                          persistent-hint
                          dense
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-card tile elevation="0">
                      <form>
                        <fieldset>
                          <legend class="text-h6 font-weight-regular text--secondary">
                            Tipo de afectaci&oacute;n
                          </legend>
                          <v-row>
                            <v-col
                              cols="12"
                              md="4"
                              v-for="(t, index) in tiposAfectaciones"
                              :key="t.id"
                            >
                              <v-checkbox
                                v-model="afectaciones"
                                :label="t.nombre"
                                :value="t.id"
                                required
                                :rules="afectacionRules"
                                :hide-details="index != 0"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </fieldset>
                      </form>
                    </v-card>
                  </v-card-text>
                </v-form>
                <v-card-actions>
                  <v-btn text @click="paso = 1"> Regresar </v-btn>
                  <v-btn color="primary" @click="validarPaso2"> Siguiente </v-btn>
                </v-card-actions>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card-subtitle class="text-center"
                  ><v-icon left>mdi-image</v-icon
                  ><strong
                    >Si cuenta con evidencia o pruebas relacionadas con su denuncia,
                    adjuntelas aquí</strong
                  ></v-card-subtitle
                >
                <v-card-text>
                  <VueFileAgent
                    ref="vueFileAgent"
                    :theme="$vuetify.breakpoint.mobile ? 'list' : 'grid'"
                    :multiple="true"
                    :deletable="true"
                    :meta="true"
                    :accept="'image/*, video/*'"
                    :maxSize="'10MB'"
                    :maxFiles="5"
                    :helpText="'Elige las fotos o videos'"
                    :errorText="{
                      type:
                        'Tipo de archivo inválido. Se acepta unicamente videos y/o fotos',
                      size: 'Cada archivo no debe exceder los 10 MB',
                    }"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    v-model="fileRecords"
                  ></VueFileAgent>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="paso = 2"> Regresar </v-btn>
                  <v-btn color="primary" @click="paso = 4"> Siguiente </v-btn>
                </v-card-actions>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card-subtitle v-if="$vuetify.breakpoint.mobile" class="text-center"
                  ><v-icon left>mdi-account</v-icon
                  ><strong
                    >Informaci&oacute;n del Servidor P&uacute;blico Denunciado</strong
                  ></v-card-subtitle
                >
                <v-form ref="form4" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Nombre o alias"
                          v-model="nombreSp"
                          prepend-inner-icon="mdi-account"
                          outlined
                          dense
                          hint="Nombre o alias del servidor público (Si se conoce)"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Cargo"
                          v-model="cargoSp"
                          prepend-inner-icon="mdi-account-tie"
                          outlined
                          dense
                          hint="Indique el area o departamente al que pertenece el servidor público (Si se conoce)"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          :items="generos"
                          v-model="generoSp"
                          item-value="id"
                          item-text="descripcion"
                          label="Genero"
                          outlined
                          prepend-inner-icon="mdi-gender-male-female"
                          dense
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-file-input
                          label="Fotografía"
                          show-size
                          prepend-inner-icon="mdi-attachment"
                          outlined
                          prepend-icon=""
                          accept="image/png, image/jpeg, image/jpg"
                          hint="Fotografía del servidor público (Opcional)"
                          persistent-hint
                          dense
                          @change="handleSPFile"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-textarea
                          label="Descripción"
                          v-model="descripcionSp"
                          prepend-inner-icon="mdi-account-edit"
                          outlined
                          dense
                          hint="Describa físicamente al servidor público"
                          persistent-hint
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-actions>
                  <v-btn text @click="paso = 3"> Regresar </v-btn>
                  <v-btn color="primary" @click="validarPaso4"> Siguiente </v-btn>
                </v-card-actions>
              </v-stepper-content>
              <v-stepper-content step="5">
                <v-card-subtitle v-if="$vuetify.breakpoint.mobile" class="text-center"
                  ><v-icon left>mdi-account-voice</v-icon
                  ><strong>Informaci&oacute;n del Denunciante</strong></v-card-subtitle
                >
                <v-form ref="form5" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Nombre"
                          v-model="nombreDenunciante"
                          prepend-inner-icon="mdi-account"
                          outlined
                          dense
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Apellido paterno"
                          v-model="paternoDenunciante"
                          prepend-inner-icon="mdi-account"
                          outlined
                          dense
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Apellido materno"
                          v-model="maternoDenunciante"
                          prepend-inner-icon="mdi-account"
                          outlined
                          dense
                          required
                          :rules="requeridoRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                          :items="generos"
                          v-model="generoDenunciante"
                          item-value="id"
                          item-text="descripcion"
                          label="Genero"
                          outlined
                          prepend-inner-icon="mdi-gender-male-female"
                          dense
                          hint="Para fines estadísticos"
                          persistent-hint
                          :rules="requeridoRules"
                          required
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-select
                          :items="rangos"
                          v-model="edadDenunciante"
                          item-value="id"
                          item-text="descripcion"
                          label="Rango de edad en años"
                          outlined
                          prepend-inner-icon="mdi-timetable"
                          dense
                          hint="Para fines estadísticos"
                          persistent-hint
                          :rules="requeridoRules"
                          required
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Correo electrónico"
                          v-model="correoDenunciante"
                          prepend-inner-icon="mdi-email"
                          outlined
                          readonly
                          filled
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Teléfono"
                          v-model="telefonoDenunciante"
                          prepend-inner-icon="mdi-phone-classic"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          label="Código Postal"
                          v-model="cpDenunciante"
                          prepend-inner-icon="mdi-map-marker-circle"
                          outlined
                          dense
                          hint="Para fines estadísticos"
                          persistent-hint
                          required
                          :rules="cpDenuncianteRules"
                        >
                          <template v-slot:append>
                            <v-icon right color="red" size="10"> mdi-asterisk</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-btn text @click="paso = 4"> Regresar </v-btn>
                <v-btn color="primary" @click="validarPaso5"> Enviar </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    //Config
    qr_dependencia: 0,
    paso: 1,
    //DATE PICKER
    activePicker: "YEAR",
    menu: false,
    selectedDate: "",
    //Form Validation
    requeridoRules: [(v) => !!v || "Este campo es requerido"],
    cpDenuncianteRules: [
      (v) => !!v || "Este campo es requerido",
      (v) => (v && v.length == 5) || "Código postal debe ser de 5 dígitos",
      (v) => /\d/.test(v) || "Únicamente se aceptan números",
    ],
    //File Manager
    fileRecords: [],
    uploadUrl: "https://seicoahuila.org.mx/api/upload/pruebas",
    uploadUrlSP: "https://seicoahuila.org.mx/api/upload/foto_sp",
    deleteUrl: "https://seicoahuila.org.mx/api/pruebas/delete",
    uploadHeaders: {},
    fileRecordsForUpload: [], //pendientes de subida
    //Catalogos
    dependencias: [],
    tiposAfectaciones: [],
    generos: [],
    rangos: [],
    //Datos
    dependencia: "",
    descripcion: "",
    ubicacion: "",
    fechaEvento: "",
    area: "",
    tramite: "",
    pruebas: [],
    afectaciones: [],
    nombreSp: "",
    cargoSp: "",
    fotoSp: [],
    generoSp: "",
    descripcionSp: "",
    nombreDenunciante: "",
    paternoDenunciante: "",
    maternoDenunciante: "",
    generoDenunciante: "",
    edadDenunciante: "",
    telefonoDenunciante: "",
    cpDenunciante: "",
    correoDenunciante: "",
    fotoSPResult: "",
  }),
  computed: {
    afectacionRules() {
      return [
        this.afectaciones.length > 0 || "Debe seleccionar al menos una afectación!",
      ];
    },
  },
  mounted() {
    this.nombreDenunciante = this.$store.state.user.data.nombre;
    this.paternoDenunciante = this.$store.state.user.data.paterno;
    this.maternoDenunciante = this.$store.state.user.data.materno;
    this.correoDenunciante = this.$store.state.user.data.correo;
    const _this = this;
    let config = { headers: { "access-token": _this.$store.state.user.data.token } };
    this.uploadHeaders = { "access-token": _this.$store.state.user.data.token };
    axios
      .get("https://seicoahuila.org.mx/api/dependencias", config)
      .then(function (response) {
        _this.dependencias = response.data;
      })
      .catch((error) => {
        _this.onError(error);
      });
    axios
      .get("https://seicoahuila.org.mx/api/generos", config)
      .then(function (response) {
        _this.generos = response.data;
      })
      .catch((error) => {
        _this.onError(error);
      });
    axios
      .get("https://seicoahuila.org.mx/api/afectaciones", config)
      .then(function (response) {
        _this.tiposAfectaciones = response.data;
      })
      .catch((error) => {
        _this.onError(error);
      });
    axios
      .get("https://seicoahuila.org.mx/api/edades", config)
      .then(function (response) {
        _this.rangos = response.data;
      })
      .catch((error) => {
        _this.onError(error);
      });
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    selectedDate(val) {
      this.fechaEvento = this.formatDate(val);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    validarPaso1() {
      if (this.$refs.form1.validate()) {
        this.paso = 2;
      }
    },
    validarPaso2() {
      if (this.$refs.form2.validate()) {
        this.paso = 3;
      }
    },
    validarPaso4() {
      if (this.$refs.form4.validate()) {
        this.paso = 5;
      }
    },
    validarPaso5() {
      if (this.$refs.form5.validate()) {
        this.prepararDenuncia();
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    onError(error) {
      const _this = this;
      console.log(error);
      switch (error.response.status) {
        case 401:
          _this
            .$swal({
              title: "Oops...!",
              text: "Sesión expirada, inicia sesion nuevamente!",
              icon: "error",
            })
            .then(() => {
              _this.$store.dispatch("fetchUser", null);
              _this.$router.push("/login");
            });
          break;
        default:
          _this.$swal({ title: "Oops...!", text: error.message, icon: "error" });
          break;
      }
    },
    async uploadFiles() {
      const _this = this;
      // Using the default uploader. You may use another uploader instead.
      _this.pruebas = await this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      _this.fileRecordsForUpload = [];
    },
    handleSPFile(event) {
      //console.log(event)
      this.fotoSp = event;
    },
    async uploadSPFoto() {
      let formData = new FormData();
      formData.append("file", this.fotoSp);
      const _this = this;
      const config = {
        headers: {
          "access-token": _this.$store.state.user.data.token,
          "Content-Type": "multipart/form-data",
        },
      };
      let url = `${this.$store.state.base_url}/upload/foto_sp`;
      return axios.post(url, formData, config).catch(function (error) {
        _this.onError(error);
      });
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.deleteUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected(fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    async prepararDenuncia() {
      const _this = this;
      let denuncia = {
        fecha: _this.parseDate(_this.fechaEvento),
        lugar: _this.ubicacion,
        area_dependencia: _this.area,
        tramite: _this.tramite,
        descripcion: _this.descripcion,
        id_dependencia: _this.dependencia,
        id_usuario_denunciante: _this.$store.state.user.data.uid,
        pruebas: [],
        denunciante: {
          nombre: _this.nombreDenunciante,
          paterno: _this.paternoDenunciante,
          materno: _this.maternoDenunciante,
          correo: _this.$store.state.user.data.correo,
          cp: _this.cpDenunciante,
          telefono: _this.generoDenunciante,
          genero: _this.generoDenunciante,
          edad: _this.edadDenunciante,
        },
        denunciado: {
          alias: _this.nombreSp,
          cargo: _this.cargoSp,
          //"fotografia":"/fotos/cesar.png",
          genero: _this.generoSp,
          descripcion: _this.descripcionSp,
        },
        afectacion: _this.afectaciones,
      };
      if (_this.fotoSp != "") {
        var result = await this.uploadSPFoto();
        denuncia.denunciado.fotografia = "/foto_sp/" + result.data.file.filename;
      }
      if (_this.fileRecordsForUpload.length > 0) {
        try {
          await _this.uploadFiles();
          _this.pruebas.forEach((p) => {
            if (p.status == 200) {
              let temp = {
                nombre: p.data.file.filename,
                url: "/pruebas/" + p.data.file.filename,
                mime: p.data.file.mimetype,
              };
              denuncia.pruebas.push(temp);
            }
          });
          _this.enviarDenuncia(denuncia);
        } catch (error) {
          console.log(error);
        }
      } else {
        _this.enviarDenuncia(denuncia);
      }
    },
    enviarDenuncia(denuncia) {
      const _this = this;
      let config = { headers: { "access-token": _this.$store.state.user.data.token } };
      axios
        .post("https://seicoahuila.org.mx/api/denuncias", denuncia, config)
        .then(function (response) {
          if (response.data.code == 0) {
            _this
              .$swal("Denuncia!", "Denuncia registrada correctamente!", "success")
              .then(() => _this.$router.push("/list"));
          }
        })
        .catch((error) => {
          _this.onError(error);
        });
    },
  },
};
</script>
